document.addEventListener("DOMContentLoaded", function () {
    const form = document.forms["calFormArea"];

    if (form) {
        const calculateArea = () => {
            // Lấy giá trị từ input và các select
            const fromVal = form.fromVal.value; // Giá trị nhập từ người dùng
            const calFrom = parseInt(form.calFrom.value, 10); // Đơn vị gốc
            const calTo = parseInt(form.calTo.value, 10); // Đơn vị muốn chuyển tới

            // Kiểm tra nếu người dùng chưa nhập giá trị
            if (fromVal === "") {
                form.toVal.value = "";
                return;
            }

            // Tạo mảng các công thức chuyển đổi
            const aA = [
                ["Square Meter", "iv", "iv"],
                ["Square Kilometer", "iv * 1000000", "iv / 1000000"],
                ["Square Centimeter", "iv * 0.0001", "iv / 0.0001"],
                ["Square Millimeter", "iv * 0.000001", "iv / 0.000001"],
                ["Square Micrometer", "iv * 0.000000000001", "iv / 0.000000000001"],
                ["Hectare", "iv * 10000", "iv / 10000"],
                ["Square Mile", "iv * 2589990", "iv / 2589990"],
                ["Square Yard", "iv * 0.83612736", "iv / 0.83612736"],
                ["Square Foot", "iv * 0.09290304", "iv / 0.09290304"],
                ["Square Inch", "iv * 0.000645160", "iv / 0.000645160"],
                ["Acre", "iv * 4046.8564224", "iv / 4046.8564224"]
            ];

            // Kiểm tra giá trị calFrom và calTo có hợp lệ không
            if (isNaN(calFrom) || isNaN(calTo) || calFrom < 1 || calFrom > 11 || calTo < 1 || calTo > 11) {
                form.toVal.value = "Invalid unit selection";
                return;
            }

            // Lấy công thức chuyển đổi cho đơn vị gốc và đơn vị đích
            const fromFormula = aA[calFrom - 1][1];
            const toFormula = aA[calTo - 1][2];

            // Chuyển đổi giá trị từ đơn vị gốc sang đơn vị đích
            const value = parseFloat(fromVal);
            if (isNaN(value)) {
                form.toVal.value = "Invalid input";
                return;
            }

            // Áp dụng công thức chuyển đổi
            const intermediateValue = eval(fromFormula.replace("iv", value)); // Tính toán giá trị từ công thức
            const convertedValue = eval(toFormula.replace("iv", intermediateValue)); // Chuyển đổi sang đơn vị đích

            // Hiển thị kết quả
            form.toVal.value = convertedValue;
        };

        // Gắn sự kiện khi người dùng thay đổi hoặc nhập dữ liệu
        form.fromVal.addEventListener("keyup", calculateArea);
        form.calFrom.addEventListener("change", calculateArea);
        form.calTo.addEventListener("change", calculateArea);
    }
});
