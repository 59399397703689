document.addEventListener("DOMContentLoaded", function () {
    let resetButtons = document.querySelectorAll("button[type='reset']");

    if (resetButtons.length === 0) return; // Kiểm tra nếu không có nút reset nào thì dừng luôn

    resetButtons.forEach(button => {
        button.addEventListener("click", function () {
            let form = this.closest("form"); // Tìm form chứa nút reset

            if (!form) return; // Kiểm tra nếu form không tồn tại thì thoát

            setTimeout(() => {
                let inputs = form.querySelectorAll("input[type='text'], input[type='hidden']");

                if (inputs.length === 0) return; // Kiểm tra nếu không có input nào thì thoát

                inputs.forEach(input => {
                    input.value = ""; // Xóa dữ liệu trong input
                });
            }, 10); // Đợi một chút để reset mặc định trước khi xóa
        });
    });
});