document.addEventListener("DOMContentLoaded", function () {
    const form = document.forms["calFormWeight"]; // Lấy đối tượng form

    if (form) {
        const tinhToanTrongLuong = () => {
            // Lấy giá trị từ input và các select
            const fromVal = form.querySelector("[name='fromVal']").value; // Giá trị nhập từ người dùng
            const calFrom = parseInt(form.querySelector("[name='calFrom']").value, 10); // Đơn vị gốc
            const calTo = parseInt(form.querySelector("[name='calTo']").value, 10); // Đơn vị muốn chuyển tới

            // Kiểm tra nếu người dùng chưa nhập giá trị
            if (fromVal === "") {
                form.querySelector("[name='toVal']").value = "";
                return;
            }

            // Tạo mảng các công thức chuyển đổi
            const wA = [
                ["Kilogram", "iv", "iv"],
                ["Gram", "iv * 0.001", "iv / 0.001"],
                ["Milligram", "iv * 0.000001", "iv / 0.000001"],
                ["Metric Ton", "iv * 1000", "iv / 1000"],
                ["Long Ton", "iv * 1016.04608", "iv / 1016.04608"],
                ["Short Ton", "iv * 907.184", "iv / 907.184"],
                ["Pound", "iv * 0.453592", "iv / 0.453592"],
                ["Ounce", "iv * 0.0283495", "iv / 0.0283495"],
                ["Carrat", "iv * 0.0002", "iv / 0.0002"],
                ["Atomic Mass Unit", "iv * 1.6605401999104288e-27", "iv / 1.6605401999104288e-27"]
            ];

            // Kiểm tra giá trị calFrom và calTo có hợp lệ không
            if (isNaN(calFrom) || isNaN(calTo) || calFrom < 1 || calFrom > 10 || calTo < 1 || calTo > 10) {
                form.querySelector("[name='toVal']").value = "Chọn đơn vị không hợp lệ";
                return;
            }

            // Lấy công thức chuyển đổi cho đơn vị gốc và đơn vị đích
            const fromFormula = wA[calFrom - 1][1];
            const toFormula = wA[calTo - 1][2];

            // Chuyển đổi giá trị từ đơn vị gốc sang đơn vị đích
            const value = parseFloat(fromVal);
            if (isNaN(value)) {
                form.querySelector("[name='toVal']").value = "Dữ liệu nhập không hợp lệ";
                return;
            }

            // Áp dụng công thức chuyển đổi
            const intermediateValue = eval(fromFormula.replace("iv", value)); // Tính toán giá trị từ công thức
            const convertedValue = eval(toFormula.replace("iv", intermediateValue)); // Chuyển đổi sang đơn vị đích

            // Hiển thị kết quả
            form.querySelector("[name='toVal']").value = convertedValue;
        };

        // Gắn sự kiện khi người dùng thay đổi hoặc nhập dữ liệu
        form.querySelector("[name='fromVal']").addEventListener("keyup", tinhToanTrongLuong);
        form.querySelector("[name='calFrom']").addEventListener("change", tinhToanTrongLuong);
        form.querySelector("[name='calTo']").addEventListener("change", tinhToanTrongLuong);
    }
});
