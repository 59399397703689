function calculLength() {
    const form = document.forms["calFormLength"];
    
    const fromVal = form.fromVal.value; // Giá trị nhập từ người dùng
    const calFrom = parseInt(form.calFrom.value, 10); // Đơn vị gốc
    const calTo = parseInt(form.calTo.value, 10); // Đơn vị muốn chuyển tới

    // Kiểm tra nếu người dùng chưa nhập giá trị
    if (fromVal === "") {
        form.toVal.value = "";
        return;
    }

    // Kiểm tra giá trị calFrom và calTo có hợp lệ không
    if (isNaN(calFrom) || isNaN(calTo) || calFrom < 1 || calFrom > 11 || calTo < 1 || calTo > 11) {
        form.toVal.value = "Invalid unit selection";
        return;
    }

    // Tạo mảng các công thức chuyển đổi
    const lA = [
        ["Meter", "iv", "iv"],
        ["Kilometer", "iv*1000", "iv/1000"],
        ["Centimeter", "iv*0.01", "iv/0.01"],
        ["Decimet", "iv*0.1", "iv/0.1"],
        ["Millimeter", "iv*0.001", "iv/0.001"],
        ["Micrometer", "iv*0.000001", "iv/0.000001"],
        ["Nanometer", "iv*0.000000001", "iv/0.000000001"],
        ["Mile", "iv*1609.35", "iv/1609.35"],
        ["Yard", "iv*0.9144", "iv/0.9144"],
        ["Foot", "iv*0.3048", "iv/0.3048"],
        ["Inch", "iv*0.0254", "iv/0.0254"],
        ["Light Year", "iv*9.46066e+15", "iv/9.46066e+15"]
    ];

    // Lấy công thức chuyển đổi cho đơn vị gốc và đơn vị đích
    const fromFormula = lA[calFrom - 1][1];
    const toFormula = lA[calTo - 1][2];

    // Chuyển đổi giá trị từ đơn vị gốc sang đơn vị đích
    const value = parseFloat(fromVal);
    if (isNaN(value)) {
        form.toVal.value = "Invalid input";
        return;
    }

    // Áp dụng công thức chuyển đổi
    const intermediateValue = eval(fromFormula.replace("iv", value)); // Tính toán giá trị từ công thức
    const convertedValue = eval(toFormula.replace("iv", intermediateValue)); // Chuyển đổi sang đơn vị đích

    // Hiển thị kết quả
    form.toVal.value = convertedValue;
}

// Đảm bảo thêm sự kiện khi DOM sẵn sàng
document.addEventListener("DOMContentLoaded", function () {
    const form = document.forms["calFormLength"];
    if (form) {
        form.fromVal.addEventListener("keyup", calculLength);
        form.calFrom.addEventListener("change", calculLength);
        form.calTo.addEventListener("change", calculLength);
    }
});