document.addEventListener("DOMContentLoaded", function () {
    const form = document.forms["calFormTemperature"];

    const calculateTemperature = () => {
        // Lấy giá trị từ input và các select
        const fromVal = form.fromVal.value; // Giá trị nhập từ người dùng
        const calFrom = parseInt(form.calFrom.value, 10); // Đơn vị gốc
        const calTo = parseInt(form.calTo.value, 10); // Đơn vị muốn chuyển tới

        // Kiểm tra nếu người dùng chưa nhập giá trị
        if (fromVal === "") {
            form.toVal.value = "";
            return;
        }

        // Tạo mảng các công thức chuyển đổi
        const lA = [
            ["Celsius", "iv", "iv"],
            ["Kelvin", "iv - 273.15", "iv + 273.15"],
            ["Fahrenheit", "5/9*(iv-32)", "9/5*iv + 32"]
        ];

        // Kiểm tra giá trị calFrom và calTo có hợp lệ không
        if (isNaN(calFrom) || isNaN(calTo) || calFrom < 1 || calFrom > 3 || calTo < 1 || calTo > 3) {
            form.toVal.value = "Invalid unit selection";
            return;
        }

        // Lấy công thức chuyển đổi cho đơn vị gốc và đơn vị đích
        const fromFormula = lA[calFrom - 1][1];
        const toFormula = lA[calTo - 1][2];

        // Chuyển đổi giá trị từ đơn vị gốc sang đơn vị đích
        const value = parseFloat(fromVal);
        if (isNaN(value)) {
            form.toVal.value = "Invalid input";
            return;
        }

        // Áp dụng công thức chuyển đổi
        const intermediateValue = eval(fromFormula.replace("iv", value)); // Tính toán giá trị từ công thức
        const convertedValue = eval(toFormula.replace("iv", intermediateValue)); // Chuyển đổi sang đơn vị đích

        // Hiển thị kết quả
        form.toVal.value = convertedValue;
    };

    // Gắn sự kiện khi người dùng thay đổi hoặc nhập dữ liệu
    if (form) {
        form.fromVal.addEventListener("keyup", calculateTemperature);
        form.calFrom.addEventListener("change", calculateTemperature);
        form.calTo.addEventListener("change", calculateTemperature);
    }
});
