document.addEventListener("DOMContentLoaded", function () {
    const form = document.forms["calFormVolume"];

    const calculateVolume = () => {
        // Lấy giá trị từ input và các select
        const fromVal = form.fromVal.value; // Giá trị nhập từ người dùng
        const calFrom = parseInt(form.calFrom.value, 10); // Đơn vị gốc
        const calTo = parseInt(form.calTo.value, 10); // Đơn vị muốn chuyển tới

        // Kiểm tra nếu người dùng chưa nhập giá trị
        if (fromVal === "") {
            form.toVal.value = "";
            return;
        }

        // Tạo mảng các công thức chuyển đổi
        const vA = [
            ["Cubic Meter", "iv", "iv"],
            ["Cubic Kilometer", "iv * 1000000000", "iv / 1000000000"],
            ["Cubic Centimeter", "iv * 0.000001", "iv / 0.000001"],
            ["Cubic Millimeter", "iv * 1.0e-9", "iv / 1.0e-9"],
            ["Liter", "iv * 0.001", "iv / 0.001"],
            ["Milliliter", "iv * 0.000001", "iv / 0.000001"],
            ["US Gallon", "iv * 0.00378541", "iv / 0.00378541"],
            ["US Quart", "iv * 0.0009463525", "iv / 0.0009463525"],
            ["US Pint", "iv * 0.00047317625", "iv / 0.00047317625"],
            ["US Cup", "iv * 0.000236588125", "iv / 0.000236588125"],
            ["US Fluid Ounce", "iv * 0.000029573515625", "iv / 0.000029573515625"],
            ["US Table Spoon", "iv * 0.0000147867578125", "iv / 0.0000147867578125"],
            ["US Tea Spoon", "iv * 4.9289192708333333333333333333333e-6", "iv / 4.9289192708333333333333333333333e-6"],
            ["Imperial Gallon", "iv * 0.00454609", "iv / 0.00454609"],
            ["Imperial Quart", "iv * 0.0011365225", "iv / 0.0011365225"],
            ["Imperial Pint", "iv * 0.00056826125", "iv / 0.00056826125"],
            ["Imperial Fluid Ounce", "iv * 0.0000284130625", "iv / 0.0000284130625"],
            ["Imperial Table Spoon", "iv * 0.0000177581640625", "iv / 0.0000177581640625"],
            ["Imperial Tea Spoon", "iv * 5.9193880208333333333333333333333e-6", "iv / 5.9193880208333333333333333333333e-6"],
            ["Cubic Mile", "iv * 4.16818e+9", "iv / 4.16818e+9"],
            ["Cubic Yard", "iv * 0.764554857984", "iv / 0.764554857984"],
            ["Cubic Foot", "iv * 0.028316846592", "iv / 0.028316846592"],
            ["Cubic Inch", "iv * 0.000016387064", "iv / 0.000016387064"]
        ];

        // Kiểm tra giá trị calFrom và calTo có hợp lệ không
        if (isNaN(calFrom) || isNaN(calTo) || calFrom < 1 || calFrom > 23 || calTo < 1 || calTo > 23) {
            form.toVal.value = "Invalid unit selection";
            return;
        }

        // Lấy công thức chuyển đổi cho đơn vị gốc và đơn vị đích
        const fromFormula = vA[calFrom - 1][1];
        const toFormula = vA[calTo - 1][2];

        // Chuyển đổi giá trị từ đơn vị gốc sang đơn vị đích
        const value = parseFloat(fromVal);
        if (isNaN(value)) {
            form.toVal.value = "Invalid input";
            return;
        }

        // Áp dụng công thức chuyển đổi
        const intermediateValue = eval(fromFormula.replace("iv", value)); // Tính toán giá trị từ công thức
        const convertedValue = eval(toFormula.replace("iv", intermediateValue)); // Chuyển đổi sang đơn vị đích

        // Hiển thị kết quả
        form.toVal.value = convertedValue;
    };

    // Gắn sự kiện khi người dùng thay đổi hoặc nhập dữ liệu
    form.fromVal.addEventListener("keyup", calculateVolume);
    form.calFrom.addEventListener("change", calculateVolume);
    form.calTo.addEventListener("change", calculateVolume);
});
